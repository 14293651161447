import React from 'react'
import { Container } from 'react-bootstrap'

import HomeSegment from './HomeSegment';
import AmericaWestRide from './AmericaWestRide';
import OfferSegment from './OfferSegment';
import ServiceSegment from './ServiceSegment';
import PartsSegment from "./PartsSegment";
import Partners from './Partners';
import AboutUs from './AboutUs';
import Work from './Work';

const HomePage = () => {
  return (
    <>
      <HomeSegment />
      <AmericaWestRide />
      <Container className="mt-4 col-12 col-md-10">
      </Container>
      <AboutUs />
      <Work />
      <Partners />
      <OfferSegment />
      <ServiceSegment />
      <PartsSegment />
    </>
  )
}

export default HomePage
