import React, { useState } from 'react'
import { Toast, ToastContainer, Row, Container, Image, Navbar } from 'react-bootstrap'

import { ArrowBarDown } from 'react-bootstrap-icons'

import logo from '../images/cm_logo.png'
import NavLinks from './NavigationBarInner'

const HomeSegment = () => {

  let [show, setShow] = useState(true)

  return (
    <>
      {/* <ToastContainer position="bottom-start">
        <Toast className="m-3" show={show} onClose={() => setShow(false)}>
          <Toast.Header>
            <strong className="me-auto">UWAGA! Zmiana godzin pracy od 1.10.2022 do 25.02.2023</strong>
          </Toast.Header>
          <Toast.Body>
            <p>Pn. - Pt. <s>10.00 - 17.00</s> 10.00 - 15.00<br />Sb. 10.00 - 14.00</p>
            <p>Istnieje możliwość umówienia się na spotkanie poza godzinami pracy - w takiej sytuacji prosimy o kontakt telefoniczny, mailowy lub SMS</p>
            <a style={{color:"black",textDecoration:"none"}} href="tel:501222108">☎️ +48 501 222 108 ☎️</a><br/>
            <a style={{color:"black",textDecoration:"none"}} href="mailto:centrum.motocyklowe@gmail.com">✉️ centrum.motocyklowe@gmail.com ✉️</a>
          </Toast.Body>
        </Toast>
      </ToastContainer> */}
      <div id="home">
        <Navbar variant="dark" expand="lg">
          <Container className="d-block">
            <NavLinks white />
          </Container>
        </Navbar>
        <Container className="col-12 d-flex align-items-center" style={{ height:"75vh", width: "100%" }}>
          <Row className="mx-auto w-100">
            <Container className="col-12 col-md-4 text-center">
              <Image src={logo} style={{ height: "25vh" }} />
            </Container>
            <Container className="col-12 col-md-8 pt-3 m-auto text-center">
              <h1 className="text-white">Centrum Motocyklowe<br />Jelenia Góra</h1>
            </Container>
          </Row>
        </Container>
        <Container className="col-12 col-md-3 text-center text-white">
          <a style={{color:"white",textDecoration:"none"}} href="#partners"><ArrowBarDown size={64} /></a>
        </Container>
      </div>
    </>
  )
}

export default HomeSegment