import { Container, Image } from 'react-bootstrap'
import img from '../images/bon_paliwowy.PNG'

const Work = () => {
  return (
    <Container id="work" className="mt-4 mb-2">
      <h3 className="text-center mb-3">Promocja</h3>
      <Image src={img} className="w-100" />
    </Container>
  )
}

export default Work
